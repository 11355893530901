
























































import { v4 as uuid } from 'uuid'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { AnyObject, IEventbus } from '@movecloser/front-core'

import { ImageProps } from '../../../../../dsl/atoms/Image'

import { toImageProps } from '../../../../../front/shared/support/'

import { AllowedImageRatio, IRelatedService } from '../../../../../contexts'

import {
  PlayerState,
  UiYTPlayerStateChangedEventPayload,
  VideoModuleContent
} from '../../../Video.contracts'

import { YouTubeIframeLoader } from './helpers'

/**
 * Component capable of rendering the `EmbedModule`
 * with the version set to `EmbedVersion.YouTube`.
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<YouTubeUi>({
  name: 'YouTubeUi',
  mounted (): void {
    if (this.$refs.video && this.$refs.video instanceof Element) {
      this.videoAttrId = this.$refs.video.getAttribute('id')
    }

    this.registerListeners()
  },
  beforeDestroy (): void {
    this.removeListeners()
  }
})

export class YouTubeUi extends Vue {
  /**
   * Autoplay trigger.
   */
  @Prop({ type: Boolean, required: false })
  protected readonly autoplay?: boolean

  /**
   * Additional description of the embedded content.
   */
  @Prop({ type: String, required: false })
  public readonly description?: VideoModuleContent['description']

  /**
   * Service capable of handling and emitting the miscellaneous events throughout the application.
   */
  @Prop({ type: Object, required: true })
  public readonly eventBus?: IEventbus

  /**
   * Service capable of resolving the related data.
   */
  @Prop({ type: Object, required: true })
  public readonly relatedService!: IRelatedService

  /**
   * Additional video cover.
   */
  @Prop({ type: Object, required: false })
  public readonly thumbnail?: VideoModuleContent['thumbnail']

  /**
   * ID of the YouTube video.
   */
  @Prop({ type: String, required: true })
  public readonly videoId!: VideoModuleContent['videoId']

  /**
   * Determines whether the YouTube player is active.
   */
  public isYoutubePlayerActive: boolean = false

  /**
   * YouTube player instance.
   */
  private player: AnyObject | null = null

  /**
   * Very important for SSR.
   */
  private videoAttrId: string | null = `${uuid()}-video`
  private labelId: string = `${uuid()}-title`

  public get image (): ImageProps {
    if (!this.thumbnail) {
      return this.youTubeCover
    }

    return toImageProps(this.thumbnail, AllowedImageRatio.Original)
  }

  public get youTubeCover (): ImageProps {
    return {
      src: `https://img.youtube.com/vi/${this.videoId}/0.jpg`,
      alt: 'YouTube cover video'
    }
  }

  /**
   * Focuses the close button after modal is opened.
   */
  public focusOnInit (): void {
    this.$nextTick(() => {
      const elementToFocus = (this.$refs.modalClose as HTMLDivElement)

      if (elementToFocus) {
        elementToFocus.focus()

        this.trapFocus(true)
      }
    })
  }

  /**
   * Determines whether the `videoId` prop has been defined and has content.
   */
  public get hasVideoId (): boolean {
    return typeof this.videoId === 'string' && this.videoId.length > 0
  }

  private onKeyDown (event: KeyboardEvent): void {
    if (!this.isYoutubePlayerActive) {
      return
    }

    if (event.key === 'Escape') {
      this.closeModal()
    }
  }

  /**
   * Dispatch when mouse is over YT.
   */
  public onMouseEnter (): void {
    if (!this.autoplay || this.isYoutubePlayerActive) {
      return
    }

    this.initPlayer(true)
  }

  /**
   * Handles the `@click` event on the "play" button.
   */
  public onPlayBtnClick (): void {
    if (this.player !== null) {
      return this.showModal()
    }

    this.initPlayer(true)
  }

  /**
   * Pause youtube video.
   */
  public pauseVideo (): void {
    if (!this.player) {
      return
    }

    this.player.pauseVideo()
  }

  /**
   * Play youtube video.
   */
  public playVideo (): void {
    if (!this.player) {
      return
    }

    this.player.playVideo()
    this.focusOnInit()
  }

  /**
   * Close modal.
   */
  public showModal (): void {
    this.isYoutubePlayerActive = true
    this.playVideo()
    this.trapFocus(true)
  }

  /**
   * Close modal.
   */
  public closeModal (): void {
    this.isYoutubePlayerActive = false
    this.pauseVideo()
    this.trapFocus(false)

    // focus trigger element after closing the video
    this.$nextTick(() => {
      if (this.$refs.modalTrigger && '$el' in this.$refs.modalTrigger && this.$refs.modalTrigger.$el instanceof Element) {
        (this.$refs.modalTrigger.$el as HTMLButtonElement).focus()
      }
    })
  }

  /**
   * Register listener on modal wrapper.
   */
  public registerListeners (): void {
    if (!this.$refs.modalWrapper) {
      return
    }

    (this.$refs.modalWrapper as HTMLElement).addEventListener('click', this.onClick)
    window.addEventListener('keydown', this.onKeyDown.bind(this))
  }

  /**
   * Remove listener on modal wrapper.
   */
  public removeListeners (): void {
    if (!this.$refs.modalWrapper) {
      return
    }

    (this.$refs.modalWrapper as HTMLElement).removeEventListener('click', this.onClick)
    window.removeEventListener('keydown', this.onKeyDown.bind(this))
  }

  public onClick (): void {
    if (!this.isYoutubePlayerActive) {
      return
    }

    this.closeModal()
  }

  private trapFocus (doTrap: boolean): void {
    const FOCUSABLE_ELEMENT_SELECTORS = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]'
    const wrapper = this.$refs.modalWrapper as HTMLDivElement
    const focusableElements = wrapper.querySelectorAll(FOCUSABLE_ELEMENT_SELECTORS)

    // There can be containers without any focusable element
    if (focusableElements.length > 0) {
      const firstFocusableEl = focusableElements[0] as HTMLElement
      const lastFocusableEl = focusableElements[focusableElements.length - 1] as HTMLElement

      const keyboardHandler = function keyboardHandler (e: KeyboardEvent) {
        if (e.key !== 'Tab') {
          return
        }

        if (e.shiftKey && document.activeElement === firstFocusableEl) {
          e.preventDefault()
          lastFocusableEl.focus()
        } else if (!e.shiftKey && document.activeElement === lastFocusableEl) {
          e.preventDefault()
          firstFocusableEl.focus()
        }
      }

      if (doTrap) {
        window.addEventListener('keydown', keyboardHandler)
      } else {
        window.removeEventListener('keydown', keyboardHandler)
      }
    }
  }

  /**
   * Watch is 'isYoutubePlayerActive' state changed.
   */
  @Watch('isYoutubePlayerActive')
  private onPlayerStatusChanged () {
    const body = document.body

    if (!body) {
      return
    }

    body.style.overflowY = this.isYoutubePlayerActive ? 'hidden' : ''
  }

  /**
   * Initialises the YouTube player.
   *
   * @param autoplay - Determines whether the video should start playing
   *   immediately after the player has been initialised.
   * @param muted - Determine whether the video should be started with volume 0.
   */
  private initPlayer (autoplay: boolean = false, muted: boolean = false): void {
    if (!this.hasVideoId || typeof this.eventBus === 'undefined') {
      return
    }

    this.eventBus.handle(
      'ui:yt-player.state-changed',
      (event: UiYTPlayerStateChangedEventPayload) => {
        if (this.player === null || typeof event.payload === 'undefined') {
          return
        }

        const { player, state } = event.payload

        if (!player || player.id === this.player.id) {
          return
        }

        if (state === PlayerState.Playing) {
          this.player.pauseVideo()
        }
      }
    )

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    YouTubeIframeLoader.load(YT => {
      this.player = new YT.Player(this.videoAttrId, {
        height: '100%',
        width: '100%',
        videoId: this.videoId,
        playerVars: {
          enablejsapi: 1,
          disablekb: 1,
          origin: window.location.origin,
          host: `${window.location.protocol}//www.youtube.com`
        },
        events: {
          onReady: ({ target }: any) => {
            if (!autoplay || !this.player) {
              return
            }

            if (muted) {
              target.mute()
            }

            target.playVideo()
            this.focusOnInit()
          },
          onStateChange: (event: { target: AnyObject; data: PlayerState }) => {
            if (typeof this.eventBus === 'undefined') {
              return
            }
            const { data: state } = event

            if (typeof state !== 'number') {
              console.warn(`YT.Player.onStateChange(): Expected [event.data] to be a type of [number], but got [${typeof state}]!`)
              return
            }

            if (this.player === null) {
              console.warn('YT.Player.onStateChange(): FATAL! [this.player] is [null]!')
              return
            }

            const payload: UiYTPlayerStateChangedEventPayload['payload'] = {
              player: this.player,
              state
            }

            this.eventBus.emit('ui:yt-player.state-changed', payload)
          }
        }
      })
    })

    this.isYoutubePlayerActive = true
  }
}

export default YouTubeUi
